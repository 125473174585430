
import SeanceList from '@/components/Contents/SeanceContent/SeanceList.vue'
import { mapGetters } from 'vuex'
export default {
  components: { SeanceList },
  computed: {
    ...mapGetters({
      getPopularProducts: 'popularProducts/getPopularProducts',
    }),
  },
  async mounted() {
    const filter = {
      page: 1,
      search: '',
      limit: 12,
      type: 2,
      categoryId: -1,
      currency: this.$store.getters['user/getUserIP'].currency,
    }
    await this.$store.dispatch('seances/GET_SEANCES', filter)
  },
}
