
import { convertToSlug } from '~/helpers/generate-slug'
import { Currency } from '~/plugins/Currency'
import { mapGetters } from 'vuex'
import CryptoJS from '~/helpers/rezz-crypto'
const Crypto = new CryptoJS('rezzApp-Por21LdxMl3Jk')
export default {
  props: {
    company: {
      type: Object,
    },
    product: {
      type: Object,
    },
    room: {
      type: Object,
    },
    service: {
      type: Object,
    },
  },
  data() {
    return {
      token: '',
    }
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'user/getActiveUser',
    }),
  },
  methods: {
    Currency,
    convertToSlug,
    setToken() {
      const token = {
        companyId: this.company.id,
        roomId: this.room.id,
        companyServiceIds: [this.service.id],
        phone: this.getActiveUser.phone || null,
        name: this.getActiveUser.fullname || null,
        price: this.service.price || null,
      }
      this.token = Crypto.crypto(JSON.stringify(token))
    },
  },
  async mounted() {
    this.setToken()
  },
}
