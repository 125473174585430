
const REZZ_APP_URL = 'https://api.rezzapp.com'
import SeanceCard from './SeanceCard.vue'
export default {
  components: {
    SeanceCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    inner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      company: null,
      room: null,
      services: [],
    }
  },
  methods: {
    async GET_SEANCE_PROCESSES(value) {
      this.isLoading = true

      if (
        value.includes('?filter[slug]=') &&
        value.includes('&filter[roomId]=') &&
        value.includes('&filter[companyServiceIds][]=')
      ) {
        const response = await this.request()
        if (response.message === 'OK') {
          this.company = {
            id: response.data.id,
            name: response.data.name,
            slug: response.data.slug,
            currency: response.data.currency,
          }
          this.room = response.data.roomInfo
          this.services = response.data.companyServicesInfo
        }
      }
      this.isLoading = false
    },
    request() {
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      }

      return fetch(
        REZZ_APP_URL + '/Company/GetCompanyInfoBySlug' + this.product.processes,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => console.error(error))
    },
  },
  mounted() {
    console.log(this.product.processes)

    this.GET_SEANCE_PROCESSES(this.product.processes)
  },
}
